import { Component, Inject } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-vecino-sucess-modal',
  standalone: true,
  imports: [MatCard, MatButton, MatButtonModule, MatIconModule],
  templateUrl: './vecino-sucess-modal.component.html',
  styleUrl: './vecino-sucess-modal.component.scss',
})
export class VecinoSucessModalComponent {
  constructor(
    public dialogRef: MatDialogRef<VecinoSucessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  navigateTo(url: string): void {
    this.dialogRef.close();
  }
}
