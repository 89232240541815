import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BuscarVecinoFormService } from '../../../services/buscar-vecino-form.service';
import { merge, pipe, takeUntil } from 'rxjs';
import { FormErrorsPipe } from '../../../utils/pipes/form-errors.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-buscar-vecino-form-simple',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    FormErrorsPipe,
    CommonModule,
  ],
  templateUrl: './buscar-vecino-form-simple.component.html',
  styleUrl: './buscar-vecino-form-simple.component.scss',
})
export class BuscarVecinoFormSimpleComponent implements OnInit {
  buscarVecinoFormSimple: FormGroup = this._fb.group({});

  dniNumberLabel: string = 'documento';

  tiposDocumento: any[] = [
    { id: 1, description: 'DNI' },
    { id: 2, description: 'Pasaporte' },
  ];

  errorMessage: string = '';

  @Output() submit$: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
    private _fb: FormBuilder,
    private _buscarVecinoFormService: BuscarVecinoFormService
  ) {}

  ngOnInit() {
    this._formBuilder();
    this.buscarVecinoFormSimple
      .get('tipoDocumento')
      ?.valueChanges.subscribe((e) => {
        if (e) {
          switch (e) {
            case 1:
              this.dniNumberLabel = 'documento';
              break;
            default:
              this.dniNumberLabel = 'pasaporte';
              break;
          }
        }
      });
  }

  cambiarBusqueda(): void {
    this._buscarVecinoFormService.formSeleccionado$.next(1);
  }

  submit(): void {
    if (this.buscarVecinoFormSimple.invalid) {
      this.buscarVecinoFormSimple.markAllAsTouched();
      return;
    }
    this.submit$.emit(this.buscarVecinoFormSimple);
  }

  private _formBuilder(): void {
    this.buscarVecinoFormSimple = this._fb.group({
      tipoDocumento: ['', [Validators.required]],
      nroDocumento: [
        '',
        [
          Validators.required,
          Validators.min(1000000),
          Validators.max(999999999999),
        ],
      ],
    });
  }
}
