import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuscarVecinoFormService {
  buscarVecinoFormSimple$: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject<FormGroup | null>(null);
  buscarVecinoFormAvanzado$: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject<FormGroup | null>(null);

  formSeleccionado$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor() {}
}
