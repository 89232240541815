import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Permissions } from '../enum/permission.enum';
import { AuthService } from '../services/auth.service';

export const permissionsGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const postPermission = authService.checkUserRole(Permissions.BUV_POST);
  const putPermission = authService.checkUserRole(Permissions.BUV_PUT);
  if (!(postPermission && putPermission)) {
    router.navigate(['busqueda']);
  }

  return postPermission && putPermission;
};
