import { Component, OnInit } from '@angular/core';
import { BuscarVecinoFormSimpleComponent } from './components/buscar-vecino-form-simple/buscar-vecino-form-simple.component';
import { BehaviorSubject } from 'rxjs';
import { BuscarVecinoFormService } from '../services/buscar-vecino-form.service';
import { BuscarVecinoFormAvanzadoComponent } from './components/buscar-vecino-form-avanzado/buscar-vecino-form-avanzado.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuscarVecinoResultadosTableComponent } from './components/buscar-vecino-resultados-table/buscar-vecino-resultados-table.component';
import { VECINOSEJEMPLO } from './const/ejemploVecinos';
import { MatDialog } from '@angular/material/dialog';
import { GenericFilter } from '../interfaces/genericFilter.interface';
import { BuvService } from '../services/buv.service';
import { Vecino } from '../interfaces/vecino.interface';
import { VecinoQueryErrorModalComponent } from '../registrar-vecino-main/components/vecino-query-error-modal/vecino-query-error-modal.component';

@Component({
  selector: 'app-buscar-vecino',
  standalone: true,
  imports: [
    BuscarVecinoFormSimpleComponent,
    BuscarVecinoFormAvanzadoComponent,
    BuscarVecinoResultadosTableComponent,
  ],
  templateUrl: './buscar-vecino.component.html',
  styleUrl: './buscar-vecino.component.scss',
})
export class BuscarVecinoComponent implements OnInit {
  formSeleccionado: number = 0;

  resultadoVecinos$: BehaviorSubject<Vecino[]> = new BehaviorSubject<Vecino[]>(
    []
  );

  showEmptyResultSimpleRequest$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  mostrarTablaResultados: boolean = false;

  private _form: FormGroup | null = this._fb.group({});

  constructor(
    private _buscarVecinoFormService: BuscarVecinoFormService,
    private _buvService: BuvService,
    private _fb: FormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._buscarVecinoFormService.formSeleccionado$.subscribe((formType) => {
      this.resultadoVecinos$.next([]);
      this.mostrarTablaResultados = false;
      this.formSeleccionado = formType;
    });
  }

  submit(form: FormGroup): void {
    this._form = form;
    let filter: GenericFilter;
    if (this.formSeleccionado == 0) {
      this.showEmptyResultSimpleRequest$.next(false);
      filter = this._getBusquedaSimpleFilter();
    } else {
      filter = this._getBusquedaAvanzadaFilter();
      if (Object.values(filter).every((value) => value === '')) {
        this.dialog.open(VecinoQueryErrorModalComponent, {
          width: '35vw',
          data: {
            message:
              'Debe ingresar al menos un campo para realizar la búsqueda',
          },
        });
        return;
      }
    }
    this._buvService.buvGetVecinos(filter).subscribe({
      next: (vecinos) => {
        this.resultadoVecinos$.next(vecinos);
        if (vecinos.length === 0 && this.formSeleccionado === 0) {
          this.showEmptyResultSimpleRequest$.next(true);
        } else {
          this.showEmptyResultSimpleRequest$.next(false);
        }
        this.mostrarTablaResultados = true;
      },
      error: (err) => {
        this._showServerErrorModal();
      },
    });
  }

  private _showServerErrorModal(): void {
    this.dialog.open(VecinoQueryErrorModalComponent, {
      width: '35vw',
    });
  }

  private _getBusquedaSimpleFilter(): GenericFilter {
    const { nroDocumento, tipoDocumento } = this._form?.value;
    this._buscarVecinoFormService.buscarVecinoFormSimple$.next(this._form);
    this._buscarVecinoFormService.buscarVecinoFormAvanzado$.next(null);
    switch (tipoDocumento) {
      case 1:
        return { numeroDocumento: nroDocumento, tipoDeDocumento: 'DNI' };
      //QUESTION: Como es el pasaporte en la base de datos
      default:
        return { numeroDocumento: nroDocumento };
    }
  }

  private _getBusquedaAvanzadaFilter(): GenericFilter {
    //Agregar el tipo de telefono
    this._buscarVecinoFormService.buscarVecinoFormSimple$.next(null);
    this._buscarVecinoFormService.buscarVecinoFormAvanzado$.next(this._form);
    const {
      nombre,
      apellido,
      sexoId,
      localidadId,
      calle,
      altura,
      codPostal,
      email,
      telefono,
    } = this._form?.value;

    return {
      nombre: nombre,
      apellido: apellido,
      sexo: this._getSexoLetter(sexoId),
      correoElectronico: email,
      telefono: telefono,
    };
  }

  private _getSexoLetter(sexoId: number): string {
    switch (sexoId) {
      case 1:
        return 'F';
      case 2:
        return 'M';
      case 3:
        return 'X';
      default:
        return '';
    }
  }
}
