import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { filter, findIndex, Observable } from 'rxjs';
import { StoreVecinoFormService } from '../../../services/store-vecino-form.service';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { VecinoContacto } from '../../../interfaces/vecinoContacto.interface';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { BuvService } from '../../../services/buv.service';
import { Vecino } from '../../../interfaces/vecino.interface';
import { FormErrorsPipe } from '../../../utils/pipes/form-errors.pipe';

@Component({
  selector: 'app-vecino-contacto-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    FormErrorsPipe,
  ],
  templateUrl: './vecino-contacto-form.component.html',
  styleUrl: './vecino-contacto-form.component.scss',
})
export class VecinoContactoFormComponent implements OnInit {
  @Input() requestFormSignal$: Observable<boolean> = new Observable();
  modoEdicion: boolean = false;
  editandoContactoVecino: boolean = false;

  tipoContacto: any[] = [
    { id: 1, description: 'Correo electronico' },
    { id: 2, description: 'Celular' },
    { id: 3, description: 'Telefono' },
    { id: 4, description: 'Tel. Laboral' },
  ];

  tipoContactoFiltered: any[] = this.tipoContacto;

  tiposPropiedad: any[] = [
    { id: 1, description: 'Es propio' },
    { id: 2, description: 'Es de terceros' },
  ];

  vecinoContactoForm: FormGroup = this._fb.group({});

  labelValorContacto: string = 'Correo electronico';
  placeholderValorContacto: string = 'email@example.com';

  contactos: VecinoContacto[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ['tipoContacto', 'valor', 'accion'];

  tipoContantoAEditar: string | null = null;

  vecino: Vecino | null = null;
  showHasChanges: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _storeVecinoFormService: StoreVecinoFormService,
    private _buvService: BuvService
  ) {}

  ngOnInit(): void {
    this._formBuilder();
    this.requestFormSignal$.subscribe((e) => {
      if (e) {
        this._sendFormToService();
      }
    });
    this._buvService.vecinoAEditar$.subscribe((vecinoAEditar) => {
      this.vecino = vecinoAEditar;
      this.contactos = [];
      if (vecinoAEditar) {
        this.editandoContactoVecino = true;
        this._parseContactosToArray(vecinoAEditar);
        this._filtrarOpcionesTipoContacto();
        this.dataSource = new MatTableDataSource(this.contactos);
      } else {
        this.editandoContactoVecino = false;
        this.vecinoContactoForm.reset();
      }
    });

    this.vecinoContactoForm
      .get('tipoContacto')
      ?.valueChanges.subscribe((tipoContacto) => {
        if (!tipoContacto) return;
        const valueField = this.vecinoContactoForm.get('valorContacto');
        if (!valueField) return;
        valueField.clearValidators();

        switch (tipoContacto) {
          case 1:
            this.placeholderValorContacto = 'example@email.com';
            this.labelValorContacto = 'Correo electronico';
            valueField.setValidators([Validators.required, Validators.email]);

            break;
          case 2:
            this.placeholderValorContacto = '+54901112345678';
            this.labelValorContacto = 'Celular';
            valueField.setValidators([
              Validators.required,
              Validators.min(1000000),
              Validators.max(99999999999999),
            ]);

            break;
          case 3:
            this.placeholderValorContacto = '+549080035319';
            this.labelValorContacto = 'Telefono';
            valueField.setValidators([
              Validators.required,
              Validators.min(1000000),
              Validators.max(99999999999999),
            ]);

            break;
          default:
            this.placeholderValorContacto = '+549080035319';
            this.labelValorContacto = 'Tel. Laboral';
            valueField.setValidators([
              Validators.required,
              Validators.min(1000000),
              Validators.max(99999999999999),
            ]);

            break;
        }
        valueField.updateValueAndValidity();
      });
  }

  getTipoContacto(tipoContactoLabel: string): string {
    const tipoContactoId = Number(tipoContactoLabel);
    const tipoConacto = this.tipoContacto.find((e) => e.id == tipoContactoId);
    return tipoConacto.description;
  }

  guardarNuevoContacto(): void {
    //Si voy a editar un elemento, no deberia poder ingresar aqui.
    if (this.modoEdicion) return;

    if (this.vecinoContactoForm.invalid) {
      this.vecinoContactoForm.markAllAsTouched();
      return;
    }
    let { tipoContacto, valorContacto } = this.vecinoContactoForm.value;

    if (valorContacto === '' || valorContacto === null) {
      return;
    }
    if (tipoContacto === '' || tipoContacto === null) {
      return;
    }
    tipoContacto = Number(tipoContacto);

    if (this.editandoContactoVecino) {
      if (this.vecino) {
        switch (tipoContacto) {
          case 1:
            this.vecino.correoElectronico = valorContacto;
            break;
          case 2:
            this.vecino.celular = valorContacto;
            break;
          case 3:
            this.vecino.telefono = valorContacto;
            break;
          default:
            this.vecino.telefonoLaboral = valorContacto;
            break;
        }
      }
    }
    this.contactos.push({
      tipoContacto: String(tipoContacto),
      contactoDescripcion: valorContacto,
    });
    this._storeVecinoFormService.contactos$.next(this.contactos);
    this._filtrarOpcionesTipoContacto();
    this.dataSource = new MatTableDataSource(this.contactos);
    this.vecinoContactoForm.reset();
    this.vecinoContactoForm.get('valorContacto')?.clearValidators();
    this.vecinoContactoForm.get('valorContacto')?.updateValueAndValidity();

    this.showHasChanges = true;
  }

  guardarEdicionContacto(): void {
    if (this.vecinoContactoForm.invalid) {
      this.vecinoContactoForm.markAllAsTouched();
      return;
    }
    let { valorContacto } = this.vecinoContactoForm.value;

    if (valorContacto === '' || valorContacto === null) {
      return;
    }
    let tipoContacto = this.tipoContantoAEditar;

    //Si estoy editando un vecino, deberia reemplazar su informacion:
    if (this.editandoContactoVecino) {
      if (this.vecino) {
        switch (Number(tipoContacto)) {
          case 1:
            this.vecino.correoElectronico = valorContacto;
            break;
          case 2:
            this.vecino.celular = valorContacto;
            break;
          case 3:
            this.vecino.telefono = valorContacto;
            break;
          default:
            this.vecino.telefonoLaboral = valorContacto;
            break;
        }
        this._buvService.vecinoAEditar$.next(this.vecino);
        this.contactos = [];
        this._parseContactosToArray(this.vecino);
      }
    }
    //Si no estoy editando un vecino, debo cambiar la informacion de la lista de contactos
    else {
      this.contactos = this.contactos.map((contacto) => {
        if (contacto.tipoContacto == tipoContacto) {
          contacto.contactoDescripcion = valorContacto;
        }
        return contacto;
      });
    }
    this.modoEdicion = false;
    this._storeVecinoFormService.contactos$.next(this.contactos);
    this._filtrarOpcionesTipoContacto();
    this.dataSource = new MatTableDataSource(this.contactos);
    this.vecinoContactoForm.reset();
    this.vecinoContactoForm.get('valorContacto')?.clearValidators();
    this.vecinoContactoForm.get('valorContacto')?.updateValueAndValidity();
    this.tipoContantoAEditar = null;
    this.showHasChanges = true;
    this.vecinoContactoForm.get('tipoContacto')?.enable();
  }

  editarContacto(vecinoContacto: VecinoContacto): void {
    this._filtrarOpcionesTipoContacto();

    this.modoEdicion = true;
    if (
      !this.tipoContactoFiltered.find(
        (t) => t.id === Number(vecinoContacto.tipoContacto)
      )
    ) {
      const tipoContactoAEditar = this.tipoContacto.find(
        (t) => t.id === Number(vecinoContacto.tipoContacto)
      );

      this.tipoContactoFiltered.push(tipoContactoAEditar);
      this.tipoContantoAEditar = tipoContactoAEditar.id;
    }
    this._llenarCamposContacto(vecinoContacto);
    this.vecinoContactoForm.get('tipoContacto')?.disable();
  }

  cancelarEdicion(): void {
    this.vecinoContactoForm.reset();
    this._filtrarOpcionesTipoContacto();
    this.modoEdicion = false;
    this.vecinoContactoForm.get('tipoContacto')?.enable();
  }

  eliminarContacto(contacto: any): void {
    this._eliminarContactoItem(contacto);
  }

  private _eliminarContactoItem(contacto: any): void {
    //Si estoy editando la informacion de un vecino, deberia vaciar su campo
    if (this.vecino) {
      switch (contacto.tipoContacto) {
        case '1':
          this.vecino.correoElectronico = '';
          break;
        case '2':
          this.vecino.celular = '';
          break;
        case '3':
          this.vecino.telefono = '';
          break;
        default:
          this.vecino.telefonoLaboral = '';
          break;
      }
      this._parseContactosToArray(this.vecino);
      this._filtrarOpcionesTipoContacto();
      this._buvService.vecinoAEditar$.next(this.vecino);
    } else {
      const index = this.contactos.findIndex(
        (c) =>
          c.tipoContacto === contacto.tipoContacto &&
          c.contactoDescripcion === contacto.contactoDescripcion
      );

      if (index !== -1) {
        this.contactos.splice(index, 1);
      } else {
        console.warn(
          `Contacto con tipo ${contacto.tipoContacto} y descripción ${contacto.contactoDescripcion} no encontrado.`
        );
      }

      if (this.contactos.length === 0) {
        this.showHasChanges = false;
      }

      this._filtrarOpcionesTipoContacto();
    }

    this.dataSource = new MatTableDataSource(this.contactos);
  }

  private _llenarCamposContacto(vecinoContacto: VecinoContacto): void {
    if (!this.tipoContantoAEditar) return;
    this.vecinoContactoForm.patchValue({
      tipoContacto: Number(this.tipoContantoAEditar),
      valorContacto: vecinoContacto.contactoDescripcion,
    });
  }

  private _filtrarOpcionesTipoContacto(): void {
    const idsContactos = this.contactos.map((c) => String(c.tipoContacto));
    this.tipoContactoFiltered = this.tipoContacto.filter((t) => {
      return !idsContactos.includes(t.id.toString());
    });
  }

  private _parseContactosToArray(vecino: Vecino): void {
    const contactos = [
      { tipoContacto: '1', contactoDescripcion: vecino.correoElectronico },
      { tipoContacto: '2', contactoDescripcion: vecino.celular },
      { tipoContacto: '3', contactoDescripcion: vecino.telefono },
      { tipoContacto: '4', contactoDescripcion: vecino.telefonoLaboral },
    ].filter((c) => c.contactoDescripcion) as VecinoContacto[];

    this.contactos = contactos;
  }

  private _sendFormToService(): void {
    this.vecinoContactoForm.clearValidators();

    if (this.contactos.length > 0) {
      this.vecinoContactoForm.reset();
      this._storeVecinoFormService.contactos$.next(this.contactos);
      this._storeVecinoFormService.vecinoContactoForm$.next(
        this.vecinoContactoForm
      );
    }
    if (this.vecinoContactoForm.invalid) {
      this.vecinoContactoForm.markAllAsTouched();
      return;
    }
  }

  private _formBuilder(): void {
    this.vecinoContactoForm = this._fb.group({
      tipoContacto: [],
      valorContacto: [],
    });
  }
}
