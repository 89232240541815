<mat-card appearance="outlined" class="mb-5">
  <div class="card-container m-5">
    <mat-card-header>
      <mat-card-title class="form-title text-base">Resultados</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-5">
      <!-- Mostrar cuando no hay resultados -->
      <ng-container
        *ngIf="
          showEmptyResultSimpleRequest || dataSource.data.length === 0;
          else showTable
        "
      >
        <ng-container *ngTemplateOutlet="noResultsTemplate"></ng-container>
      </ng-container>

      <!-- Tabla para mostrar resultados -->
      <ng-template #showTable>
        <div class="table-responsive">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z0 pt-5 min-w-full"
          >
            <!-- Columnas dinámicas -->
            <ng-container
              *ngFor="let column of tableColumns"
              [matColumnDef]="column.field"
            >
              <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
              <td mat-cell *matCellDef="let element">
                <ng-container [ngSwitch]="column.field">
                  <!-- Contenido dinámico según la columna -->
                  <span *ngSwitchCase="'dni'">{{
                    element.numeroDocumento
                  }}</span>
                  <span *ngSwitchCase="'nombre'">{{
                    element.nombre || "Sin nombre"
                  }}</span>
                  <span *ngSwitchCase="'apellido'">{{ element.apellido }}</span>
                  <span *ngSwitchCase="'fechaUpdate'">{{
                    element.updated_at | date : "dd/MM/yyyy"
                  }}</span>
                  <span *ngSwitchCase="'fechaCreate'">{{
                    element.created_at | date : "dd/MM/yyyy"
                  }}</span>
                  <ng-container *ngSwitchCase="'renaper'">
                    <div class="flex flex-row items-center gap-1">
                      <mat-icon
                        [ngClass]="
                          element.origenValidacion === 'RENAPER'
                            ? 'validated'
                            : 'not-validated'
                        "
                      >
                        {{
                          element.origenValidacion === "RENAPER"
                            ? "check_circle"
                            : "cancel"
                        }}
                      </mat-icon>
                      <span class="text-nowrap">{{
                        element.origenValidacion === "RENAPER"
                          ? "validado"
                          : "no validado"
                      }}</span>
                    </div>
                  </ng-container>
                  <span *ngSwitchCase="'buv'">
                    {{ "Nivel " + (element.nivelValidacion || 0) }}
                  </span>
                  <a
                    *ngSwitchCase="'accion'"
                    [routerLink]="['/edicion/' + element.id]"
                    class="underline"
                    >Acceder</a
                  >
                  <mat-icon
                    *ngSwitchCase="'emailValidado'"
                    (click)="resendEmailValidation(element)"
                    class="resend-email-icon cursor-pointer"
                    [matTooltip]="'Reenviar email'"
                    >forward_to_inbox</mat-icon
                  >
                  <mat-icon
                    *ngSwitchCase="'restablecerContraseña'"
                    (click)="restorePassword(element)"
                    class="resend-email-icon cursor-pointer"
                    [matTooltip]="'Restablecer contraseña'"
                    >manage_history</mat-icon
                  >
                </ng-container>
              </td>
            </ng-container>

            <!-- Filas de encabezado y contenido -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </ng-template>
    </mat-card-content>
  </div>
</mat-card>

<!-- Plantilla para no hay resultados -->
<ng-template #noResultsTemplate>
  @if(userHasCreatePermission){

  <div
    class="no-results-container flex flex-col sm:flex-row justify-between items-center gap-4"
  >
    <div class="text-container text-center sm:text-left">
      <p class="font-bold">
        No existen resultados en la BUV que coincidan con tu búsqueda actual.
      </p>
      <p>
        Podés ajustar los datos ingresados o realizar la carga manual de un
        nuevo vecino.
      </p>
    </div>
    <div class="button-container w-full sm:w-1/4">
      <button
        mat-raised-button
        color="primary"
        class="save-button flex items-center justify-center"
        (click)="crearNuevoVecino()"
      >
        Nuevo vecino
      </button>
    </div>
  </div>
  }@else {
  <div
    class="no-results-container flex flex-col sm:flex-row justify-between items-center gap-4"
  >
    <div class="text-container text-center sm:text-left">
      <p class="font-bold">
        No existen resultados en la BUV que coincidan con tu búsqueda actual.
      </p>
      <p>
        Podés ajustar los datos ingresados o solicitar a Administración que se
        haga la carga manual del vecino.
      </p>
    </div>
  </div>
  }
</ng-template>
