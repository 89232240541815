<div class="container p-80">
  <div
    class="content flex flex-col justify-center items-center text-center gap-5"
  >
    <h2>¡Ups! Tuvimos un problema</h2>
    <mat-icon>report_outline</mat-icon>
    @if (data) {
    <h3 class="pt-5">
      {{
        data.message
          ? data.message
          : "En este momento no podemos realizar esta acción, vuelve a intentarlo nuevamente."
      }}
    </h3>
    }@else {
    <h3 class="pt-5">
      En este momento no podemos realizar esta acción, vuelve a intentarlo
      nuevamente.
    </h3>
    }

    <button
      mat-raised-button
      color="primary"
      class="save-button m-auto w-full"
      (click)="reintentar()"
    >
      Reintentar
    </button>
  </div>
</div>
