<mat-toolbar class="shadow-lg">
  <div
    class="toolbar-container flex flex-row justify-between items-center w-full"
  >
    <div class="mx-16">
      <img src="./../../../assets/logo-vic-lopez.png" alt="" srcset="" />
    </div>
    @if (showToolbarUserSettings) {

    <div class="user-container flex flex-row items-center gap-5">
      <div class="flex flex-col">
        <span>{{ userName }}</span>
        <span>{{ userRole }}</span>
      </div>
      <div class="logout-container">
        <button mat-button (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </div>
    }
  </div>
</mat-toolbar>
