import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-vecino-error-modal',
  standalone: true,
  imports: [MatCard, MatButton, MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './vecino-error-modal.component.html',
  styleUrl: './vecino-error-modal.component.scss',
})
export class VecinoErrorModalComponent {
  errores: string[] = ['1(un) domicilio declarado', '1(un) dato de contacto'];

  constructor(
    public dialogRef: MatDialogRef<VecinoErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.errores = this.data;
  }

  regresar(): void {
    this.dialogRef.close();
  }
}
