<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <div
        class="text-header-container flex flex-row justify-between items-center w-full"
      >
        <mat-card-title class="form-title">
          Información personal del vecino
        </mat-card-title>

        <ng-container *ngIf="showHasChanges">
          <button mat-raised-button class="changes-button">
            Hay cambios sin guardar
          </button>
        </ng-container>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="form-container text-left pt-8">
        <form
          [formGroup]="vecinoPersonaForm"
          class="flex flex-col gap-6 w-full"
        >
          <div class="flex flex-col sm:flex-row gap-6 w-full">
            <!-- tipo documento -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="tipoDocumento">
                  <mat-option
                    *ngFor="let tipoDni of tiposDni"
                    [value]="tipoDni.id"
                  >
                    {{ tipoDni.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="vecinoPersonaForm.get('tipoDocumento')?.touched"
              >
                {{
                  vecinoPersonaForm.get("tipoDocumento")!.errors | formErrors
                }}
              </mat-error>
            </div>

            <!-- nro documento -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Número Documento</mat-label>
                <input
                  matInput
                  placeholder="12121212"
                  formControlName="nroDocumento"
                  type="number"
                />
              </mat-form-field>
              <mat-error *ngIf="vecinoPersonaForm.get('nroDocumento')?.touched">
                {{ vecinoPersonaForm.get("nroDocumento")!.errors | formErrors }}
              </mat-error>
            </div>

            <!-- Validar con RENAPER -->
            <div
              class="button-container flex flex-col items-center w-full sm:w-1/3"
            >
              <button
                mat-raised-button
                color="primary"
                class="save-button flex items-center justify-center mb-2"
                (click)="validarConRenaper()"
                [disabled]="true"
              >
                Validar con RENAPER
              </button>
            </div>
          </div>

          <div class="flex flex-col sm:flex-row gap-6 w-full">
            <!-- nombre -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Álvaro" formControlName="nombre" />
              </mat-form-field>
              <mat-error *ngIf="vecinoPersonaForm.get('nombre')?.touched">
                {{ vecinoPersonaForm.get("nombre")!.errors | formErrors }}
              </mat-error>
            </div>

            <!-- apellido -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Apellido</mat-label>
                <input
                  matInput
                  placeholder="Restrepo"
                  formControlName="apellido"
                />
              </mat-form-field>
              <mat-error *ngIf="vecinoPersonaForm.get('apellido')?.touched">
                {{ vecinoPersonaForm.get("apellido")!.errors | formErrors }}
              </mat-error>
            </div>

            <!-- CUIL -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>CUIL</mat-label>
                <div class="cuil-container w-full justify-center">
                  <input
                    matInput
                    [value]="vecinoPersonaForm.get('cuilPrefijo')?.value"
                    (input)="onCuilChange($event, 'cuilPrefijo')"
                    (focus)="preventFocusRedirect($event)"
                    (click)="preventFocusRedirect($event)"
                    [disabled]="modoEdicion"
                    maxlength="2"
                    class="input-side text-center"
                  />
                  <input
                    matInput
                    [value]="vecinoPersonaForm.get('cuilDni')?.value"
                    (input)="onCuilChange($event, 'cuilDni')"
                    (focus)="preventFocusRedirect($event)"
                    (click)="preventFocusRedirect($event)"
                    [disabled]="modoEdicion"
                    maxlength="8"
                    class="input-central text-center"
                  />
                  <input
                    matInput
                    [value]="vecinoPersonaForm.get('cuilSufijo')?.value"
                    (input)="onCuilChange($event, 'cuilSufijo')"
                    (focus)="preventFocusRedirect($event)"
                    (click)="preventFocusRedirect($event)"
                    [disabled]="modoEdicion"
                    maxlength="1"
                    class="input-side text-center"
                  />
                </div>
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngIf="modoEdicion">
            <div class="w-full -mt-5">
              <mat-list class="w-full">
                <div
                  class="flex flex-wrap md:flex-nowrap justify-between items-center w-full px-3 gap-y-2 md:gap-y-0"
                >
                  <p class="w-full md:w-auto text-center md:text-left">
                    Últ. modificación: {{ ultimaModificacion | date }}
                  </p>
                  <div
                    class="flex flex-wrap md:flex-nowrap items-center gap-x-5 gap-y-2 md:gap-y-0 justify-center md:justify-end w-full md:w-auto"
                  >
                    <p class="whitespace-nowrap text-center md:text-left">
                      Origen de Validación: {{ origenValidacion }}
                    </p>
                    <p class="whitespace-nowrap text-center md:text-left">
                      Nivel {{ nivelDeValidacion }}
                    </p>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </mat-list>
            </div>
          </ng-container>

          <div class="flex flex-col sm:flex-row gap-6 w-full">
            <!-- Fecha de Nacimiento -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Fecha de Nacimiento</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="fecNacimiento"
                  [max]="maxDate"
                />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-error
                class="dp-error"
                *ngIf="vecinoPersonaForm.get('fecNacimiento')?.touched"
              >
                {{
                  vecinoPersonaForm.get("fecNacimiento")!.errors | formErrors
                }}
              </mat-error>
            </div>

            <!-- Sexo -->
            <div class="mat-form-div w-full sm:w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="sexo">
                  <mat-option
                    *ngFor="let tipoSexo of tiposSexo"
                    [value]="tipoSexo.id"
                  >
                    {{ tipoSexo.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="vecinoPersonaForm.get('sexo')?.touched">
                {{ vecinoPersonaForm.get("sexo")!.errors | formErrors }}
              </mat-error>
            </div>

            <div class="w-full sm:w-1/3"></div>
          </div>
        </form>
        <span class="flex-grow">
          Importante: Los datos señalados con * son obligatorios
        </span>
      </div>
    </mat-card-content>
  </div>
</mat-card>
