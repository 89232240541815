import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VecinoPersonaFormComponent } from './registrar-vecino-main/components/vecino-persona-form/vecino-persona-form.component';
import { VecinoDireccionFormComponent } from './registrar-vecino-main/components/vecino-direccion-form/vecino-direccion-form.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from './components/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToolbarComponent,
    FooterComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'ng-registro-vecino';
}
