<div class="container p-80">
  <div
    class="content flex flex-col justify-center items-center text-center gap-5"
  >
    <h2>{{ data.header }}</h2>
    <mat-icon>{{ data.icon }}</mat-icon>
    <div class="error-info-container pt-5 flex flex-col gap-2">
      <h3>{{ data.mainText }}</h3>
      <h3>
        <strong>{{ data.vecino.correoElectronico }}</strong>
      </h3>
    </div>

    <h3>
      Por favor, verifica que el correo sea el correcto antes de continuar.
    </h3>

    <button
      mat-raised-button
      color="primary"
      class="save-button m-auto w-full"
      (click)="enviar()"
    >
      Enviar
    </button>
  </div>
</div>
