export const LOCALIDADES = [
  { id: 1, descripcion: 'Almirante Brown' },
  { id: 2, descripcion: '25 de Mayo' },
  { id: 3, descripcion: 'Escobar' },
  { id: 4, descripcion: 'Alberti' },
  { id: 5, descripcion: 'Avellaneda' },
  { id: 6, descripcion: 'Adolfo Alsina' },
  { id: 7, descripcion: 'General Rodríguez' },
  { id: 8, descripcion: 'Arrecifes' },
  { id: 9, descripcion: 'Berazategui' },
  { id: 10, descripcion: 'Ayacucho' },
  { id: 11, descripcion: 'General San Martín' },
  { id: 12, descripcion: 'Baradero' },
  { id: 13, descripcion: 'Berisso' },
  { id: 14, descripcion: 'Azul' },
  { id: 15, descripcion: 'Hurlingham' },
  { id: 16, descripcion: 'Bragado' },
  { id: 17, descripcion: 'Cañuelas' },
  { id: 18, descripcion: 'Balcarce' },
  { id: 19, descripcion: 'Ituzaingó' },
  { id: 20, descripcion: 'Capitán Sarmiento' },
  { id: 21, descripcion: 'Ensenada' },
  { id: 22, descripcion: 'Benito Juárez' },
  { id: 23, descripcion: 'José C. Paz' },
  { id: 24, descripcion: 'Carlos Casares' },
  { id: 25, descripcion: 'Esteban Echeverría' },
  { id: 26, descripcion: 'Bolívar' },
  { id: 27, descripcion: 'La Matanza' },
  { id: 28, descripcion: 'Carlos Tejedor' },
  { id: 29, descripcion: 'Ezeiza' },
  { id: 30, descripcion: 'Castelli' },
  { id: 31, descripcion: 'Luján' },
  { id: 32, descripcion: 'Carmen de Areco' },
  { id: 33, descripcion: 'Florencio Varela' },
  { id: 34, descripcion: 'Chascomús' },
  { id: 35, descripcion: 'Malvinas Argentinas' },
  { id: 36, descripcion: 'Chacabuco' },
  { id: 37, descripcion: 'La Plata' },
  { id: 38, descripcion: 'Coronel Dorrego' },
  { id: 39, descripcion: 'Marcos Paz' },
  { id: 40, descripcion: 'Chivilcoy' },
  { id: 41, descripcion: 'Lanús' },
  { id: 42, descripcion: 'Coronel Pringles' },
  { id: 43, descripcion: 'Merlo' },
  { id: 44, descripcion: 'Colón y Rojas' },
  { id: 45, descripcion: 'Lomas de Zamora' },
  { id: 46, descripcion: 'Coronel Rosales' },
  { id: 47, descripcion: 'Moreno' },
  { id: 48, descripcion: 'Florentino Ameghino' },
  { id: 49, descripcion: 'Presidente Perón' },
  { id: 50, descripcion: 'Daireaux' },
  { id: 51, descripcion: 'Morón' },
  { id: 52, descripcion: 'General Arenales' },
  { id: 53, descripcion: 'Quilmes' },
  { id: 54, descripcion: 'Dolores' },
  { id: 55, descripcion: 'Pilar' },
  { id: 56, descripcion: 'General Pinto' },
  { id: 57, descripcion: 'San Vicente' },
  { id: 58, descripcion: 'General Alvarado' },
  { id: 59, descripcion: 'San Fernando' },
  { id: 60, descripcion: 'General Viamonte' },
  { id: 61, descripcion: 'General Alvear' },
  { id: 62, descripcion: 'San Isidro' },
  { id: 63, descripcion: 'General Villegas' },
  { id: 64, descripcion: 'General Belgrano' },
  { id: 65, descripcion: 'San Miguel' },
  { id: 66, descripcion: 'Hipólito Yrigoyen' },
  { id: 67, descripcion: 'General Guido' },
  { id: 68, descripcion: 'Tigre' },
  { id: 69, descripcion: 'Junín' },
  { id: 70, descripcion: 'Tres de Febrero' },
  { id: 71, descripcion: 'Leandro N. Alem' },
  { id: 72, descripcion: 'General Lavalle' },
  { id: 73, descripcion: 'Vicente López' },
  { id: 74, descripcion: 'Lincoln' },
  { id: 75, descripcion: 'General Madariaga' },
  { id: 76, descripcion: 'Mercedes' },
  { id: 77, descripcion: 'General Paz' },
  { id: 78, descripcion: 'Navarro' },
  { id: 79, descripcion: 'General Pueyrredón' },
  { id: 80, descripcion: 'Nueve de Julio' },
  { id: 81, descripcion: 'González Chávez' },
  { id: 82, descripcion: 'Pehuajó' },
  { id: 83, descripcion: 'Guaminí' },
  { id: 84, descripcion: 'Pergamino' },
  { id: 85, descripcion: 'La Costa' },
  { id: 86, descripcion: 'Ramallo' },
  { id: 87, descripcion: 'Laprida' },
  { id: 88, descripcion: 'Rivadavia' },
  { id: 89, descripcion: 'Las Flores' },
  { id: 90, descripcion: 'Salto' },
  { id: 91, descripcion: 'Lezama' },
  { id: 92, descripcion: 'San Andrés de Giles' },
  { id: 93, descripcion: 'Lobería' },
  { id: 94, descripcion: 'San Antonio de Areco' },
  { id: 95, descripcion: 'Maipú' },
  { id: 96, descripcion: 'San Nicolás' },
  { id: 97, descripcion: 'Mar Chiquita' },
  { id: 98, descripcion: 'San Pedro' },
  { id: 99, descripcion: 'Monte' },
  { id: 100, descripcion: 'Suipacha' },
  { id: 101, descripcion: 'Necochea' },
  { id: 102, descripcion: 'Trenque Lauquen' },
  { id: 103, descripcion: 'Olavarría' },
  { id: 104, descripcion: 'Zárate' },
  { id: 105, descripcion: 'Patagones' },
  { id: 106, descripcion: 'Pellegrini' },
  { id: 107, descripcion: 'Pila' },
  { id: 108, descripcion: 'Pinamar' },
  { id: 109, descripcion: 'Puán' },
  { id: 110, descripcion: 'Rauch' },
  { id: 111, descripcion: 'Roque Pérez' },
  { id: 112, descripcion: 'Saavedra' },
  { id: 113, descripcion: 'Saladillo' },
  { id: 114, descripcion: 'Salliqueló' },
  { id: 115, descripcion: 'San Cayetano' },
  { id: 116, descripcion: 'Tandil' },
  { id: 117, descripcion: 'Tapalqué' },
  { id: 118, descripcion: 'Tordillo' },
  { id: 119, descripcion: 'Tornquist' },
];
