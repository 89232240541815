import { Component, OnInit } from '@angular/core';
import { VecinoPersonaFormComponent } from './components/vecino-persona-form/vecino-persona-form.component';
import { VecinoDireccionFormComponent } from './components/vecino-direccion-form/vecino-direccion-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { VecinoContactoFormComponent } from './components/vecino-contacto-form/vecino-contacto-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BuvService } from '../services/buv.service';
import { GenericFilter } from '../interfaces/genericFilter.interface';
import { BehaviorSubject, forkJoin, map, Observable, of, Subject } from 'rxjs';
import { NuevoVecino, Vecino } from '../interfaces/vecino.interface';
import { StoreVecinoFormService } from '../services/store-vecino-form.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { VecinoSucessModalComponent } from './components/vecino-sucess-modal/vecino-sucess-modal.component';
import { VecinoQueryErrorModalComponent } from './components/vecino-query-error-modal/vecino-query-error-modal.component';
import { VecinoErrorModalComponent } from './components/vecino-error-modal/vecino-error-modal.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-registrar-vecino-main',
  standalone: true,
  imports: [
    CommonModule,
    VecinoPersonaFormComponent,
    VecinoDireccionFormComponent,
    VecinoContactoFormComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './registrar-vecino-main.component.html',
  styleUrl: './registrar-vecino-main.component.scss',
})
export class RegistrarVecinoMainComponent implements OnInit {
  requestFormSignal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  datosPersonalesForm: FormGroup | null = null;
  datosDireccionForm: FormGroup | null = null;
  datosContactoForm: FormGroup | null = null;
  arregloDirecciones: any[] = [];
  arregloContactos: any[] = [];

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _modoEdicion: boolean = false;
  private _vecinoId: number | null = null;
  private _vecinoAEditar: Vecino | null = null;

  constructor(
    private _buvService: BuvService,
    private route: ActivatedRoute,
    private _storeVecinoFormService: StoreVecinoFormService,
    private _router: Router,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this._vecinoId = params['id'];
      if (this._vecinoId) {
        this._modoEdicion = true;
        let filter: GenericFilter = {
          id: this._vecinoId.toString(),
        };
        this._buvService.buvGetVecinos(filter).subscribe((vecino) => {
          //manejar el error de si entro y no hay vecino que rediriga hacia atras de nuevo
          this._vecinoAEditar = vecino[0];
          this._buvService.vecinoAEditar$.next(vecino[0]);
        });
      } else {
        this._modoEdicion = false;
        this._buvService.vecinoAEditar$.next(null);
      }
    });

    this._storeVecinoFormService.vecinoPersonaForm$.subscribe((form) => {
      if (form) {
        this.datosPersonalesForm = form;
      }
    });
    this._storeVecinoFormService.vecinoDireccionForm$.subscribe((form) => {
      if (form) {
        this.datosDireccionForm = form;
      }
    });
    this._storeVecinoFormService.vecinoContactoForm$.subscribe((form) => {
      if (form) {
        this.datosContactoForm = form;
      }
    });
    this._storeVecinoFormService.direcciones$.subscribe((form) => {
      this.arregloDirecciones = form;
    });
    this._storeVecinoFormService.contactos$.subscribe((form) => {
      this.arregloContactos = form;
    });
  }

  submit(): void {
    this.requestFormSignal$.next(true);
    this.loading$.next(true);
    if (this._modoEdicion) {
      setTimeout(() => {
        if (!this._vecinoAEditar) return;
        this._guardarEdicionVecino(this._vecinoAEditar).subscribe({
          next: (nuevoVecino) => {
            if (nuevoVecino) {
              this._showSucessModal();
            }
          },
          error: (error) => {
            this._showServerErrorModal(error.error.error);
          },
        });
      }, 100);
    } else {
      setTimeout(() => {
        if (this.datosPersonalesForm?.invalid) {
          this.datosPersonalesForm?.markAllAsTouched();
          return;
        }
        this._guardarNuevoVecino().subscribe({
          next: (nuevoVecino) => {
            if (nuevoVecino) {
              this._showSucessModal();
            }
          },
          error: (error) => {
            console.log('Ha ocurrido un error: ', error);
            this._showServerErrorModal(error.error.error);
          },
        });
      }, 100);
    }
  }

  navigateTo(route: string): void {
    this._router.navigate([`${route}`]);
  }

  private _guardarNuevoVecino(): Observable<Vecino> {
    if (!this.datosPersonalesForm) return of(null as any);
    const formValues = this.datosPersonalesForm.value;
    const formErrors: string[] = [];
    let nuevoVecino: NuevoVecino = {
      nombre: formValues.nombre,
      apellido: formValues.apellido,
      cuil: `${formValues.cuilPrefijo}${formValues.nroDocumento}${formValues.cuilSufijo}`,
      numeroDocumento: formValues.nroDocumento,
      username: `${formValues.cuilPrefijo}${formValues.nroDocumento}${formValues.cuilSufijo}`,
      sexo: this._getSexo(formValues.sexo),
      fechaDeNacimiento: formValues.fecNacimiento,
      tipoDeDocumento: this._getTipoDocumento(formValues.tipoDocumento),
    };

    nuevoVecino = this._setContactos(nuevoVecino);
    nuevoVecino = this._setDirecciones(nuevoVecino);

    if (nuevoVecino.domicilios?.length === 0) {
      formErrors.push('1(un) domicilio declarado');
    }
    if (this.arregloContactos.length === 0) {
      formErrors.push('1(un) contacto declarado');
    }
    if (formErrors.length > 0) {
      this._showFormErrorModal(formErrors);
      return of(null as any);
    }
    return this._buvService.buvCreateVecino(nuevoVecino);
  }

  private _guardarEdicionVecino(vecinoAEditar: Vecino): Observable<Vecino> {
    let vecino: Vecino = vecinoAEditar;
    const formErrors: string[] = [];
    const formValues = this.datosPersonalesForm!.value;
    if (formValues.nombre && vecino.nombre != formValues.nombre) {
      vecino.nombre = formValues.nombre;
    }
    if (formValues.apellido && vecino.apellido != formValues.apellido) {
      vecino.apellido = formValues.apellido;
    }
    if (formValues.sexo && vecino.nombre != this._getSexo(formValues.sexo)) {
      vecino.sexo = this._getSexo(formValues.sexo);
    }
    if (formValues.fecNacimiento) {
      vecino.fechaDeNacimiento = formValues.fecNacimiento;
    }

    vecino = this._setContactos(vecino) as Vecino;
    vecino = this._setDirecciones(vecino) as Vecino;

    if (vecino.domicilios?.length === 0) {
      formErrors.push('1(un) domicilio declarado');
    }
    if (this.arregloContactos.length === 0) {
      formErrors.push('1(un) contacto declarado');
    }
    if (formErrors.length > 0) {
      this._showFormErrorModal(formErrors);
      return of(null as any);
    }

    return this._buvService.buvEditVecino(vecino);
  }

  private _getSexo(sexoId: number): string {
    switch (sexoId) {
      case 1:
        return 'M';
        break;
      case 2:
        return 'F';
        break;
      default:
        return 'Otro';
    }
  }

  private _setContactos(vecino: NuevoVecino): NuevoVecino | Vecino {
    let nuevoVecino = vecino;
    this.arregloContactos.forEach((contacto) => {
      if (contacto.tipoContacto == 1) {
        nuevoVecino.correoElectronico = contacto.contactoDescripcion;
      }
      if (contacto.tipoContacto == 2) {
        nuevoVecino.celular = contacto.contactoDescripcion;
      }
      if (contacto.tipoContacto == 3) {
        nuevoVecino.telefono = contacto.contactoDescripcion;
      }
      if (contacto.tipoContacto == 4) {
        nuevoVecino.telefonoLaboral = contacto.contactoDescripcion;
      }
    });
    if (
      !nuevoVecino.correoElectronico ||
      nuevoVecino.correoElectronico === ''
    ) {
      nuevoVecino.correoElectronico = `${nuevoVecino.cuil}@test.com`;
    }
    return nuevoVecino;
  }

  private _setDirecciones(vecino: NuevoVecino): NuevoVecino {
    let nuevoVecino = vecino;
    nuevoVecino.domicilios = this.arregloDirecciones.map((objeto) => {
      const { updated_at, id, ...resto } = objeto;
      return resto;
    });
    return nuevoVecino;
  }

  private _getTipoDocumento(tipoDocumentoId: number): string {
    switch (tipoDocumentoId) {
      case 1:
        return 'DNI';
      default:
        return ''; // O lanzar una excepción si no es válido
    }
  }

  private _showFormErrorModal(errors: string[]) {
    this.loading$.next(false);

    this._dialog.open(VecinoErrorModalComponent, {
      width: '35vw',
      data: errors,
    });
  }

  private _showServerErrorModal(message: string): void {
    this.loading$.next(false);

    this._dialog.open(VecinoQueryErrorModalComponent, {
      width: '35vw',
      data: {
        message: message,
      },
    });
  }

  private _showSucessModal(): void {
    this.loading$.next(false);

    const dialog = this._dialog.open(VecinoSucessModalComponent, {
      width: '35vw',
      data: {
        message:
          'El proceso de alta manual del vecino se ha completado con éxito',
        showButton: true,
      },
    });

    dialog.afterClosed().subscribe((e) => {
      this._router.navigate(['busqueda']);
    });
  }
}
