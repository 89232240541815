<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header class="flex flex-col-reverse">
      <h2 class="text-2xl">
        Ingresa los datos del vecino para iniciar la búsqueda
      </h2>
    </mat-card-header>
    <mat-card-content>
      <div class="form-container text-left pt-8 flex flex-col gap-4">
        <form
          [formGroup]="buscarVecinoFormSimple"
          class="flex flex-col items-center gap-4"
        >
          <mat-card-title class="form-title text-base text-left w-full">
            Filtrar por datos personales
          </mat-card-title>
          <div class="flex w-full gap-6 items-center">
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Nombre</mat-label>
              <input matInput placeholder="Álvaro" formControlName="nombre" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Apellido</mat-label>
              <input matInput placeholder="Pérez" formControlName="apellido" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>{{ "Sexo (cómo figura en el DNI)" }}</mat-label>
              <mat-select formControlName="sexoId">
                @for (sexo of tiposSexo; track sexo.id) {
                <mat-option [value]="sexo.id">{{
                  sexo.description
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <mat-card-title class="form-title text-base text-left w-full">
            Filtrar por domicilio declarado
          </mat-card-title>
          <div class="flex w-full gap-6 items-center">
            <mat-form-field appearance="outline" class="w-2/5">
              <mat-label>Localidad</mat-label>
              <mat-select formControlName="localidadId">
                @for (localidad of localidades; track localidad.id) {
                <mat-option [value]="localidad.id">{{
                  localidad.descripcion
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/5">
              <mat-label>Calle</mat-label>
              <input matInput placeholder="" formControlName="calle" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/5">
              <mat-label>Altura</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="altura"
                type="number"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/5">
              <mat-label>Código postal</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="codPostal"
                type="number"
              />
            </mat-form-field>
          </div>

          <mat-card-title class="form-title text-base text-left w-full">
            Filtrar por datos de contacto
          </mat-card-title>
          <div class="flex w-full gap-6 items-center">
            <mat-form-field appearance="outline" class="w-1/2">
              <mat-label>Correo electrónico</mat-label>
              <input matInput placeholder="" formControlName="email" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-1/2">
              <mat-label>Teléfono</mat-label>
              <input matInput placeholder="" formControlName="telefono" />
            </mat-form-field>
          </div>

          <div class="button-container w-1/5 flex flex-col items-center pt-4">
            <button
              mat-raised-button
              color="primary"
              class="save-button flex items-center justify-center mb-2"
              (click)="submit()"
            >
              Buscar <mat-icon>search</mat-icon>
            </button>
            <span (click)="cambiarBusqueda()" class="text-center"
              >Volver a la búsqueda por documento</span
            >
          </div>
        </form>
      </div>
    </mat-card-content>
  </div>
</mat-card>
