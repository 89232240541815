import { VecinoPersonaFormComponent } from './../../../registrar-vecino-main/components/vecino-persona-form/vecino-persona-form.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { VECINOSEJEMPLO } from '../../const/ejemploVecinos';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { Vecino } from '../../../interfaces/vecino.interface';
import { BuscarVecinoFormService } from '../../../services/buscar-vecino-form.service';

@Component({
  selector: 'app-buscar-vecino-resultados-table',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    CommonModule,
    RouterModule,
  ],
  templateUrl: './buscar-vecino-resultados-table.component.html',
  styleUrl: './buscar-vecino-resultados-table.component.scss',
})
export class BuscarVecinoResultadosTableComponent implements OnInit {
  @Input() resultadoVecinos$: Observable<Vecino[]> = new Observable<Vecino[]>();
  @Input() showEmptyResultSimpleRequest$: Observable<boolean> =
    new Observable<boolean>();

  displayedColumns: string[] = [
    'fechaCreate',
    'fechaUpdate',
    'dni',
    'nombre',
    'apellido',
    'renaper',
    'buv',
    'accion',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  showEmptyResultSimpleRequest: boolean = false;

  private _tipoDocumento: number = 0;
  private _nroDocumento: number = 0;

  constructor(
    private _router: Router,
    private _buscarVecinoFormService: BuscarVecinoFormService
  ) {}

  ngOnInit(): void {
    this.showEmptyResultSimpleRequest$.subscribe((show) => {
      this.showEmptyResultSimpleRequest = show;
    });
    this.resultadoVecinos$.subscribe((vecinos) => {
      this.dataSource = new MatTableDataSource(vecinos);
    });

    this._buscarVecinoFormService.buscarVecinoFormSimple$.subscribe(
      (formSimple) => {
        if (formSimple) {
          const { nroDocumento, tipoDocumento } = formSimple.value;
          this._nroDocumento = nroDocumento;
          this._tipoDocumento = tipoDocumento;
        }
      }
    );
  }

  crearNuevoVecino(): void {
    if (this._tipoDocumento && this._nroDocumento) {
      this._router.navigate(['registrar'], {
        queryParams: {
          tipoDocumento: this._tipoDocumento,
          nroDocumento: this._nroDocumento,
        },
      });
    } else {
      this._router.navigate(['registrar']);
    }
  }
}
