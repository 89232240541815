import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-reenviar-email-modal',
  templateUrl: './reenviar-email-modal.component.html',
  styleUrls: ['./reenviar-email-modal.component.scss'],
  standalone: true,
  imports: [MatIcon, MatButton],
})
export class ReenviarEmailModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ReenviarEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  enviar(): void {
    this.dialogRef.close(true);
  }
}
