import { Component, Inject } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatCard } from '@angular/material/card';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-vecino-query-error-modal',
  standalone: true,
  imports: [MatCard, MatButton, MatButtonModule, MatIconModule],
  templateUrl: './vecino-query-error-modal.component.html',
  styleUrl: './vecino-query-error-modal.component.scss',
})
export class VecinoQueryErrorModalComponent {
  constructor(
    public dialogRef: MatDialogRef<VecinoQueryErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  reintentar(): void {
    this.dialogRef.close();
  }
}
