import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoreVecinoFormService } from '../../../services/store-vecino-form.service';
import { Observable } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { IFormVecinoDomicilios } from '../../../interfaces/formVecinoDomicilios.interface';
import { Domicilio } from '../../../interfaces/domicilio.interface';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BuvService } from '../../../services/buv.service';
import { MatButtonModule } from '@angular/material/button';
import { Vecino } from '../../../interfaces/vecino.interface';
import { FormErrorsPipe } from '../../../utils/pipes/form-errors.pipe';

@Component({
  selector: 'app-vecino-direccion-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatTableModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormErrorsPipe,
  ],
  templateUrl: './vecino-direccion-form.component.html',
  styleUrl: './vecino-direccion-form.component.scss',
})
export class VecinoDireccionFormComponent implements OnInit {
  @Input() requestFormSignal$: Observable<boolean> = new Observable();

  tiposDomicilio: any[] = [
    { id: 1, description: 'Registrado' },
    { id: 2, description: 'Real' },
    { id: 3, description: 'Legal' },
    { id: 4, description: 'Laboral' },
  ];

  tiposDomicilioFiltered: any[] = this.tiposDomicilio;

  vecinoDireccionForm: FormGroup = this._fb.group({});

  modoEdicion: boolean = false;
  domicilioAEditarId: string | null = null;
  tipoDomicilioAEditarid: string | null = null;

  domicilios: Domicilio[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [
    'fechaUpdate',
    'tipoDomicilio',
    'partido',
    'localidad',
    'calle',
    'numero',
    'deptoPiso',
    'codPostal',
    'accion',
  ];

  vecino: Vecino | null = null;
  showHasChanges: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private _storeVecinoFormService: StoreVecinoFormService,
    private _buvService: BuvService
  ) {}

  ngOnInit(): void {
    this._formBuilder();
    this.requestFormSignal$.subscribe((e) => {
      if (e) {
        this._sendFormToService();
      }
    });
    this._buvService.vecinoAEditar$.subscribe((vecinoAEditar) => {
      this.vecino = vecinoAEditar;
      if (vecinoAEditar) {
        this.domicilios = vecinoAEditar.domicilios;
      } else {
        this.domicilios = [];
        this.vecinoDireccionForm.reset();
      }
      this._storeVecinoFormService.direcciones$.next(this.domicilios);
      this.dataSource = new MatTableDataSource(this.domicilios);
    });

    this._storeVecinoFormService.direcciones$.subscribe((direcciones) => {
      if (direcciones.length > 0) {
        this._filtrarOpcionesTipoDireccion();
      } else {
        this.tiposDomicilioFiltered = this.tiposDomicilio;
      }
    });
  }

  private _filtrarOpcionesTipoDireccion(): void {
    const idsDirecciones = this.domicilios.map((c) => String(c.tipoDomicilio));
    this.tiposDomicilioFiltered = this.tiposDomicilio.filter((t) => {
      return !idsDirecciones.includes(t.id.toString());
    });
  }

  getTipoDireccionId(domicilioLabel: string): string {
    const tipoDomicilio = Number(domicilioLabel);
    const domicilio = this.tiposDomicilio.find((e) => e.id == tipoDomicilio);
    return domicilio.description;
  }

  editarDireccion(domicilio: any): void {
    this.modoEdicion = true;
    const option = this.tiposDomicilio.find(
      (tipoDomicilio) => tipoDomicilio.id === Number(domicilio.tipoDomicilio)
    );

    if (!option) return;
    const optionAlreadyIncluded = this.tiposDomicilioFiltered.find(
      (tipoDomicilio) => tipoDomicilio.id === Number(domicilio.tipoDomicilio)
    );
    if (!optionAlreadyIncluded) {
      this.tiposDomicilioFiltered.push(option);
    }
    this.tipoDomicilioAEditarid = option.id;
    this.domicilioAEditarId = domicilio.id;
    this._llenarCamposDomicilio(domicilio);
    setTimeout(() => {
      this.vecinoDireccionForm.get('tipoDomicilio')?.disable();
    }, 100);
  }

  cancelarEdicion(): void {
    this.vecinoDireccionForm.reset();
    this.domicilioAEditarId = null;
    this.modoEdicion = false;
    if (this.domicilios.length > 0) {
      this._filtrarOpcionesTipoDireccion();
    }
    this.vecinoDireccionForm.get('tipoDomicilio')?.enable();
  }

  guardarNuevaDireccion(): void {
    if (this.vecinoDireccionForm.invalid) {
      this.vecinoDireccionForm.markAllAsTouched();
      return;
    }
    const formValues = this.vecinoDireccionForm.value;
    const direccionNueva: Domicilio = {
      tipoDomicilio: formValues.tipoDomicilio,
      partido: formValues.partido,
      localidad: formValues.localidad,
      cp: formValues.codPostal,
      calle: formValues.calle,
      numero: formValues.altura,
      torre: formValues.torre ? formValues.torre : '',
      piso: formValues.piso ? formValues.piso : '',
      depto: formValues.depto ? formValues.depto : '',
      updated_at: new Date().toString(),
    };
    this.domicilios.push(direccionNueva);
    this._storeVecinoFormService.direcciones$.next(this.domicilios);
    this.vecinoDireccionForm.reset();
    this.showHasChanges = true;
    this.dataSource = new MatTableDataSource(this.domicilios);
  }

  guardarEdicionDomicilio(): void {
    if (this.vecinoDireccionForm.invalid) {
      this.vecinoDireccionForm.markAllAsTouched();
      return;
    }
    if (!this.vecino || !this.tipoDomicilioAEditarid) return;
    const formValues = this.vecinoDireccionForm.value;
    const direccionEditada: Domicilio = {
      tipoDomicilio: this.tipoDomicilioAEditarid,
      partido: formValues.partido,
      localidad: formValues.localidad,
      cp: formValues.codPostal,
      calle: formValues.calle,
      numero: formValues.altura,
      torre: formValues.torre,
      piso: formValues.piso,
      depto: formValues.depto,
    };
    this.vecino.domicilios = this.vecino.domicilios.map((domicilio) => {
      if (domicilio.id === this.domicilioAEditarId) {
        return {
          ...domicilio, // Mantener propiedades existentes
          ...direccionEditada, // Añadir propiedades editadas
        };
      } else {
        return domicilio;
      }
    });
    this._buvService.vecinoAEditar$.next(this.vecino);
    this.vecinoDireccionForm.reset();
    this.modoEdicion = false;
    this.domicilioAEditarId = null;
    this.tipoDomicilioAEditarid = null;
    this.showHasChanges = true;
    this.vecinoDireccionForm.get('tipoDomicilio')?.enable();
  }

  eliminarDireccion(domicilio: any) {
    const tipoDomicilio = String(domicilio.tipoDomicilio);

    this.domicilios = this.domicilios.filter(
      (domicilio) => String(domicilio.tipoDomicilio) != tipoDomicilio
    );
    this._storeVecinoFormService.direcciones$.next(this.domicilios);
    if (!this.vecino && this.domicilios.length === 0) {
      this.showHasChanges = false;
    }
    this.dataSource = new MatTableDataSource(this.domicilios);
  }

  private _llenarCamposDomicilio(domicilio: any): void {
    if (domicilio) {
      this.vecinoDireccionForm.patchValue({
        tipoDomicilio: Number(domicilio.tipoDomicilio),
        partido: domicilio.partido,
        localidad: domicilio.localidad,
        codPostal: domicilio.cp,

        calle: domicilio.calle,
        altura: domicilio.numero,
        torre: domicilio.torre,
        piso: domicilio.piso,
        depto: domicilio.depto,
      });
    }
  }

  private _sendFormToService(): void {
    if (this.domicilios.length > 0) {
      this.vecinoDireccionForm.reset();
      this._storeVecinoFormService.direcciones$.next(this.domicilios);
      this._storeVecinoFormService.vecinoDireccionForm$.next(
        this.vecinoDireccionForm
      );
    } else {
      if (this.vecinoDireccionForm.invalid) {
        this.vecinoDireccionForm.markAllAsTouched();
        return;
      }
      this._storeVecinoFormService.vecinoDireccionForm$.next(
        this.vecinoDireccionForm
      );
    }
  }

  private _formBuilder(): void {
    this.vecinoDireccionForm = this._fb.group({
      tipoDomicilio: ['', [Validators.required]],
      partido: ['', [Validators.required]],
      localidad: ['', [Validators.required]],
      codPostal: ['', [Validators.required]],

      calle: ['', [Validators.required]],
      altura: ['', [Validators.required]],
      torre: [''],
      piso: [''],
      depto: [''],
    });
  }
}
