<div class="main-container pb-6">
  <div class="div-width-container flex flex-col items-center w-full">
    <div class="form-containers flex flex-col gap-4 w-3/4 pt-12">
      <div class="text-header-container flex flex-row justify-between">
        <h2 class="register-title text-left w-full">
          Servicio de alta manual de vecinos
        </h2>

        <button
          mat-raised-button
          class="back-button m-auto w-1/4"
          (click)="navigateTo('busqueda')"
        >
          <span class="flex flex-row items-center justify-center gap-2">
            <mat-icon>arrow_back</mat-icon> Regresar
          </span>
        </button>
      </div>
      <app-vecino-persona-form
        [requestFormSignal$]="requestFormSignal$"
      ></app-vecino-persona-form>
      <app-vecino-direccion-form
        [requestFormSignal$]="requestFormSignal$"
      ></app-vecino-direccion-form>
      <app-vecino-contacto-form
        [requestFormSignal$]="requestFormSignal$"
      ></app-vecino-contacto-form>
      <button
        mat-raised-button
        color="primary"
        class="save-button w-1/3 m-auto"
        (click)="submit()"
      >
        <span class="flex flex-row items-center justify-center gap-2">
          Guardar <mat-icon>save</mat-icon>
        </span>
      </button>
    </div>
  </div>
</div>
