<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <div
        class="text-header-container flex flex-row justify-between items-center w-full"
      >
        <mat-card-title class="form-title">
          Información personal del vecino
        </mat-card-title>

        @if(showHasChanges){
        <button mat-raised-button class="changes-button">
          Hay cambios sin guardar
        </button>
        }
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="form-container text-left pt-8">
        <form [formGroup]="vecinoPersonaForm" class="flex flex-wrap gap-6">
          <div class="flex w-full gap-6">
            <!-- tipo documento -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="tipoDocumento">
                  @for (tipoDni of tiposDni; track tipoDni.id) {
                  <mat-option [value]="tipoDni.id">{{
                    tipoDni.description
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('tipoDocumento')?.touched) {
                {{
                  vecinoPersonaForm.get("tipoDocumento")!.errors | formErrors
                }}
                }
              </mat-error>
            </div>

            <!-- nro documento -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Número Documento</mat-label>
                <input
                  matInput
                  placeholder="12121212"
                  formControlName="nroDocumento"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('nroDocumento')?.touched) {
                {{ vecinoPersonaForm.get("nroDocumento")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="button-container flex flex-col items-center w-1/3">
              <button
                mat-raised-button
                color="primary"
                class="save-button flex items-center justify-center mb-2"
                (click)="validarConRenaper()"
                [disabled]="true"
              >
                Validar con RENAPER
              </button>
            </div>
          </div>

          <div class="flex w-full gap-6">
            <!-- nombre -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Álvaro" formControlName="nombre" />
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('nombre')?.touched) {
                {{ vecinoPersonaForm.get("nombre")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <!-- apellido -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Apellido</mat-label>
                <input
                  matInput
                  placeholder="Restrepo"
                  formControlName="apellido"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('apellido')?.touched) {
                {{ vecinoPersonaForm.get("apellido")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <!-- CUIL -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>CUIL</mat-label>
                <input
                  matInput
                  placeholder="11000000002"
                  formControlName="cuil"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('cuil')?.touched) {
                {{ vecinoPersonaForm.get("cuil")!.errors | formErrors }}
                }
              </mat-error>
            </div>
          </div>

          @if (modoEdicion) {

          <div class="w-full -mt-5">
            <mat-list class="w-full">
              <div
                class="flex flex-row justify-between items-center w-full px-3"
              >
                <p>Últ. modificación: {{ ultimaModificacion | date }}</p>
                <div class="flex flex-row items-center gap-5">
                  <p class="text-nowrap">
                    Origen de Validación: {{ origenValidacion }}
                  </p>
                  <p class="text-nowrap">Nivel {{ nivelDeValidacion }}</p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list>
          </div>
          }

          <div class="flex w-full gap-6">
            <!-- Fecha de Nacimiento -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Fecha de Nacimiento</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="fecNacimiento"
                  [max]="maxDate"
                />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-error class="dp-error">
                @if (vecinoPersonaForm.get('fecNacimiento')?.touched) {
                {{
                  vecinoPersonaForm.get("fecNacimiento")!.errors | formErrors
                }}
                }
              </mat-error>
            </div>

            <!-- Sexo -->
            <div class="mat-form-div w-1/3">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="sexo">
                  @for (tipoSexo of tiposSexo; track tipoSexo.id) {
                  <mat-option [value]="tipoSexo.id">{{
                    tipoSexo.description
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-error>
                @if (vecinoPersonaForm.get('sexo')?.touched) {
                {{ vecinoPersonaForm.get("sexo")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="w-1/3"></div>
            <!-- <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Fecha de Nacimiento</mat-label>
              <input matInput formControlName="fecNacimiento" />
            </mat-form-field>
             -->
          </div>
        </form>
        <span class="flex-grow">
          Importante: Los datos señalados con * son obligatorios
        </span>
      </div>
    </mat-card-content>
  </div>
</mat-card>
