import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { Vecino } from '../../../interfaces/vecino.interface';
import { BuscarVecinoFormService } from '../../../services/buscar-vecino-form.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { ReenviarEmailModalComponent } from '../reenviar-email-modal/reenviar-email-modal.component';
import { BuvService } from '../../../services/buv.service';
import { VecinoQueryErrorModalComponent } from '../../../registrar-vecino-main/components/vecino-query-error-modal/vecino-query-error-modal.component';
import { VecinoSucessModalComponent } from '../../../registrar-vecino-main/components/vecino-sucess-modal/vecino-sucess-modal.component';
import { Permissions } from '../../../enum/permission.enum';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-buscar-vecino-resultados-table',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    CommonModule,
    RouterModule,
    MatTooltipModule,
  ],
  templateUrl: './buscar-vecino-resultados-table.component.html',
  styleUrl: './buscar-vecino-resultados-table.component.scss',
})
export class BuscarVecinoResultadosTableComponent implements OnInit {
  @Input() resultadoVecinos$: Observable<Vecino[]> = new Observable<Vecino[]>();
  @Input() showEmptyResultSimpleRequest$: Observable<boolean> =
    new Observable<boolean>();

  tableColumns = [
    { field: 'dni', header: 'DNI' },
    { field: 'nombre', header: 'Nombre' },
    { field: 'apellido', header: 'Apellido' },
    { field: 'fechaUpdate', header: 'Última edición' },
    { field: 'fechaCreate', header: 'Alta' },
    { field: 'renaper', header: 'RENAPER' },
    { field: 'buv', header: 'BUV' },
    { field: 'accion', header: 'Ver/Editar' },
    { field: 'emailValidado', header: 'Validar email' },
    { field: 'restablecerContraseña', header: 'Restablecer contraseña' },
  ];

  displayedColumns = this.tableColumns.map((col) => col.field);

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  showEmptyResultSimpleRequest: boolean = false;

  userHasCreatePermission: boolean = true;
  userHasEditPermission: boolean = true;

  private _tipoDocumento: number = 0;
  private _nroDocumento: number = 0;

  constructor(
    private _router: Router,
    private _buscarVecinoFormService: BuscarVecinoFormService,
    private _authService: AuthService,
    private _buvService: BuvService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.showEmptyResultSimpleRequest$.subscribe((show) => {
      this.showEmptyResultSimpleRequest = show;
    });
    this.resultadoVecinos$.subscribe((vecinos) => {
      this.dataSource = new MatTableDataSource(vecinos);
    });

    this._buscarVecinoFormService.buscarVecinoFormSimple$.subscribe(
      (formSimple) => {
        if (formSimple) {
          const { nroDocumento, tipoDocumento } = formSimple.value;
          this._nroDocumento = nroDocumento;
          this._tipoDocumento = tipoDocumento;
        }
      }
    );

    this.userHasCreatePermission = this._checkUserPermission(
      Permissions.BUV_POST
    );
    this.userHasEditPermission = this._checkUserPermission(Permissions.BUV_PUT);
    this.displayedColumns = this.tableColumns
      .filter((col) => {
        if (col.field === 'accion') {
          return this.userHasEditPermission;
        }
        return true;
      })
      .map((col) => col.field);
  }

  resendEmailValidation(vecino: any): void {
    const dialogRef = this._dialog.open(ReenviarEmailModalComponent, {
      width: '35vw',
      data: {
        header: 'Reenviar Correo de Validación',
        mainText:
          'Se enviará un nuevo correo electrónico al vecino para validar su dirección. El mensaje será enviado a la siguiente dirección:',
        icon: 'email',
        vecino: vecino,
      },
    });
    dialogRef.afterClosed().subscribe((event) => {
      if (event && event == true) {
        this._buvService.buvResendEmailVerification(vecino).subscribe(
          (e) => {
            this._handleSucessResponse(2);
          },
          (error) => {
            this._handleErrorResponse();
          }
        );
      }
    });
  }

  restorePassword(vecino: any): void {
    const dialogRef = this._dialog.open(ReenviarEmailModalComponent, {
      width: '35vw',
      data: {
        header: 'Restablecer Contraseña',
        mainText:
          'Se enviará un correo electrónico al vecino para restablecer su contraseña. El mensaje será enviado a la siguiente dirección:',
        icon: 'password',
        vecino: vecino,
      },
    });
    dialogRef.afterClosed().subscribe((event) => {
      if (event && event == true) {
        this._buvService.buvResetPassword(vecino).subscribe(
          (e) => {
            this._handleSucessResponse(1);
          },
          (error) => {
            this._handleErrorResponse();
          }
        );
      }
    });
  }

  crearNuevoVecino(): void {
    if (this._tipoDocumento && this._nroDocumento) {
      this._router.navigate(['registrar'], {
        queryParams: {
          tipoDocumento: this._tipoDocumento,
          nroDocumento: this._nroDocumento,
        },
      });
    } else {
      this._router.navigate(['registrar']);
    }
  }

  private _checkUserPermission(permission: Permissions): boolean {
    return this._authService.checkUserRole(permission);
  }

  private _handleSucessResponse(idOperation: number): void {
    this._dialog.open(VecinoSucessModalComponent, {
      width: '35vw',
      data: {
        message: `Se envio correctamente el correo para ${
          idOperation === 1 ? 'reestablecer la contraseña' : 'validar el vecino'
        }`,
        showButton: false,
      },
    });
  }
  private _handleErrorResponse(): void {
    this._dialog.open(VecinoQueryErrorModalComponent, {
      width: '35vw',
    });
  }
}
