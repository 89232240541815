import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { RegistrarVecinoMainComponent } from './registrar-vecino-main/registrar-vecino-main.component';
import { LoginComponent } from './login/login.component';
import { BuscarVecinoComponent } from './buscar-vecino/buscar-vecino.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './utils/guards/auth.guard';
import { permissionsGuard } from './guard/permissions.guard';

export const routes: Routes = [
  {
    path: 'registrar',
    component: RegistrarVecinoMainComponent,
    canActivate: [AuthGuard, permissionsGuard],
  },
  {
    path: 'busqueda',
    component: BuscarVecinoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./login/login.routes').then((m) => m.routes),
  },
  {
    path: 'edicion/:id',
    component: RegistrarVecinoMainComponent,
    canActivate: [AuthGuard, permissionsGuard],
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];
