import { Injectable, inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivateChild(childRoute: any, state: any): boolean {
    const isUserLogged = this._authService.isAuthenticated();
    if (!isUserLogged) {
      this._router.navigate(['auth/login']);
    }
    return isUserLogged;
  }
}

export const AuthGuard: CanActivateChildFn = (childRoute, state) => {
  return inject(AuthGuardService).canActivateChild(childRoute, state);
};
