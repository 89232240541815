<div class="container p-80">
  <div
    class="content flex flex-col justify-center items-center text-center gap-5"
  >
    <h2>¡Excelente!</h2>
    <mat-icon>check_circle_outline</mat-icon>
    <h3 class="pt-5">
      {{
        data.message
          ? data.message
          : "El proceso de alta manual del vecino se ha completado con éxito"
      }}
    </h3>

    @if (data.showButton) {
    <button
      mat-raised-button
      color="primary"
      class="save-button m-auto w-full"
      (click)="navigateTo('busqueda')"
    >
      Continuar
    </button>
    }
  </div>
</div>
