import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreVecinoFormService {
  vecinoDireccionForm$: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject<FormGroup | null>(null);
  vecinoPersonaForm$: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject<FormGroup | null>(null);
  vecinoContactoForm$: BehaviorSubject<FormGroup | null> =
    new BehaviorSubject<FormGroup | null>(null);

  direcciones$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  contactos$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  constructor() {}
}
