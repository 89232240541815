<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <div
        class="text-header-container flex flex-row justify-between items-center w-full"
      >
        <mat-card-title class="form-title">
          Domicilios registrados</mat-card-title
        >
        @if(showHasChanges){
        <button mat-raised-button class="changes-button">
          Hay cambios sin guardar
        </button>
        }
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container pt-5 responsive-table">
        @if (domicilios.length>0) {
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0 pt-5"
        >
          <ng-container matColumnDef="fechaUpdate">
            <th mat-header-cell *matHeaderCellDef>Última edición</th>
            <td mat-cell *matCellDef="let element">
              {{ element.updated_at | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="tipoDomicilio">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              {{ getTipoDireccionId(element.tipoDomicilio) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="localidad">
            <th mat-header-cell *matHeaderCellDef>Localidad</th>
            <td mat-cell *matCellDef="let element">{{ element.localidad }}</td>
          </ng-container>

          <ng-container matColumnDef="partido">
            <th mat-header-cell *matHeaderCellDef>Partido</th>
            <td mat-cell *matCellDef="let element">
              {{ element.partido }}
            </td>
          </ng-container>

          <ng-container matColumnDef="calle">
            <th mat-header-cell *matHeaderCellDef>Calle</th>
            <td mat-cell *matCellDef="let element">
              {{ element.calle }}
            </td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Altura</th>
            <td mat-cell *matCellDef="let element">
              {{ element.numero }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deptoPiso">
            <th mat-header-cell *matHeaderCellDef>Depto/Piso</th>
            <td mat-cell *matCellDef="let element">
              {{ element.depto + "/" + element.piso }}
            </td>
          </ng-container>

          <ng-container matColumnDef="codPostal">
            <th mat-header-cell *matHeaderCellDef>Cód. Postal</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cp }}
            </td>
          </ng-container>

          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef>Editar | Eliminar</th>
            <td mat-cell *matCellDef="let element">
              <div class="button-container flex flex-nowrap">
                <button
                  mat-icon-button
                  matTooltip="Edit Job"
                  (click)="editarDireccion(element)"
                >
                  <mat-icon class="edit-button">edit</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="Edit Job"
                  (click)="eliminarDireccion(element)"
                >
                  <mat-icon class="edit-button">remove</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        }@else {
        <mat-card-subtitle class="pt-2 text-center">
          No hay domicilios registrados</mat-card-subtitle
        >
        }
      </div>

      <mat-card-title class="form-title pt-8">
        Agregar domicilio</mat-card-title
      >

      <div class="form-container text-left pt-8">
        <form [formGroup]="vecinoDireccionForm" class="flex flex-wrap gap-6">
          <div class="flex flex-wrap w-full gap-6">
            <div class="mat-form-div basis-1/5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Tipo de domicilio</mat-label>
                <mat-select formControlName="tipoDomicilio">
                  @for (tipoDomicilio of tiposDomicilioFiltered; track
                  tipoDomicilio.id) {
                  <mat-option [value]="tipoDomicilio.id">{{
                    tipoDomicilio.description
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('tipoDomicilio')?.touched) {
                {{
                  vecinoDireccionForm.get("tipoDomicilio")!.errors | formErrors
                }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Partido</mat-label>
                <input
                  matInput
                  placeholder="Vicente López"
                  formControlName="partido"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('partido')?.touched) {
                {{ vecinoDireccionForm.get("partido")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Localidad</mat-label>
                <input
                  matInput
                  placeholder="Olivos"
                  formControlName="localidad"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('localidad')?.touched) {
                {{ vecinoDireccionForm.get("localidad")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Código Postal</mat-label>
                <input
                  matInput
                  placeholder="1900"
                  formControlName="codPostal"
                  type="number"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('codPostal')?.touched) {
                {{ vecinoDireccionForm.get("codPostal")!.errors | formErrors }}
                }
              </mat-error>
            </div>
          </div>

          <div class="flex flex-wrap w-full gap-6">
            <div class="mat-form-div basis-1/2">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Calle</mat-label>
                <input
                  matInput
                  placeholder="Av. Arenales"
                  formControlName="calle"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('calle')?.touched) {
                {{ vecinoDireccionForm.get("calle")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Altura</mat-label>
                <input
                  matInput
                  placeholder="1359"
                  formControlName="altura"
                  type="number"
                />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('altura')?.touched) {
                {{ vecinoDireccionForm.get("altura")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Torre</mat-label>
                <input matInput placeholder="C" formControlName="torre" />
              </mat-form-field>
              <mat-error>
                @if (vecinoDireccionForm.get('torre')?.touched) {
                {{ vecinoDireccionForm.get("torre")!.errors | formErrors }}
                }
              </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Piso</mat-label>
                <input matInput placeholder="2" formControlName="piso" />
              </mat-form-field>
              <mat-error> </mat-error>
            </div>

            <div class="mat-form-div flex-grow">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Depto</mat-label>
                <input matInput placeholder="1353" formControlName="depto" />
              </mat-form-field>
              <mat-error> </mat-error>
            </div>
          </div>
        </form>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span class="flex-grow">
          Importante: Los datos señalados con * son obligatorios
        </span>

        @if(modoEdicion) {
        <div class="buttons-container flex flex-row gap-3 basis-1/3">
          <button
            mat-raised-button
            class="save-button m-auto flex-grow"
            (click)="guardarEdicionDomicilio()"
          >
            <span class="flex flex-row items-center justify-center gap-2">
              Modificar
            </span>
          </button>
          <button
            mat-raised-button
            class="save-button-danger m-auto basis-2/5"
            (click)="cancelarEdicion()"
          >
            <span class="flex flex-row items-center justify-center gap-2">
              Cancelar
            </span>
          </button>
        </div>
        }@else {
        <button
          mat-raised-button
          color="primary"
          class="save-button m-auto basis-1/4"
          (click)="guardarNuevaDireccion()"
        >
          <span class="flex flex-row items-center justify-center gap-2">
            Agregar nueva dirección
          </span>
        </button>
        }
      </div>
    </mat-card-content>
  </div>
</mat-card>
