<div class="container p-80">
  <div
    class="content flex flex-col justify-center items-center text-center gap-5"
  >
    <h2>¡Ups! Parece que hay problemas</h2>
    <mat-icon>report_outline</mat-icon>
    <div class="error-info-container pt-5 flex flex-col gap-2">
      <h3>
        Para avanzar con el proceso de alta manual del vecino debemos contar con
        <strong>al menos:</strong>
      </h3>
      @for (error of errores; track $index) {
      <span
        ><strong>{{ error }}</strong></span
      >
      }
    </div>

    <h3>
      Alguno de estos datos está faltando, por favor, volvé al formulario para
      completarlos antes de concluir la carga.
    </h3>

    <button
      mat-raised-button
      color="primary"
      class="save-button m-auto w-full"
      (click)="regresar()"
    >
      Reintentar
    </button>
  </div>
</div>
