<div class="main-container pb-5">
  <div class="div-width-container flex flex-col items-center w-full">
    <div class="form-containers flex flex-col gap-2 w-3/4">
      <h2 class="register-title text-left">Búsqueda | Consulta</h2>
      @if (formSeleccionado===0) {
      <app-buscar-vecino-form-simple
        (submit$)="submit($event)"
      ></app-buscar-vecino-form-simple>
      }@else{
      <app-buscar-vecino-form-avanzado
        (submit$)="submit($event)"
      ></app-buscar-vecino-form-avanzado>
      } @if (mostrarTablaResultados) {

      <app-buscar-vecino-resultados-table
        [resultadoVecinos$]="resultadoVecinos$"
        [showEmptyResultSimpleRequest$]="showEmptyResultSimpleRequest$"
      ></app-buscar-vecino-resultados-table>
      }
    </div>
  </div>
</div>
