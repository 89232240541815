<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header class="flex flex-col-reverse">
      <h2 class="text-2xl pb-2">
        Ingresa los datos del vecino para iniciar la búsqueda
      </h2>
      <mat-card-title class="form-title text-base">
        Búsqueda por documento
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="form-container text-left pt-8 flex flex-col gap-4">
        <form [formGroup]="buscarVecinoFormSimple">
          <div class="flex w-full gap-6 items-center">
            <div class="mat-form-div w-2/5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Tipo de documento</mat-label>
                <mat-select formControlName="tipoDocumento">
                  @for (tipoDocumento of tiposDocumento; track tipoDocumento.id)
                  {
                  <mat-option [value]="tipoDocumento.id">{{
                    tipoDocumento.description
                  }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-error></mat-error>
            </div>

            <div class="mat-form-div w-2/5">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Número de {{ dniNumberLabel }}</mat-label>
                <input
                  matInput
                  placeholder="11222333"
                  formControlName="nroDocumento"
                  type="number"
                />
              </mat-form-field>
              <mat-error>
                @if (buscarVecinoFormSimple.get('nroDocumento')?.touched) {
                {{
                  buscarVecinoFormSimple.get("nroDocumento")!.errors
                    | formErrors
                }}
                }
              </mat-error>
            </div>

            <div class="button-container w-1/5 flex flex-col items-center">
              <button
                mat-raised-button
                color="primary"
                class="save-button flex items-center justify-center mb-2"
                (click)="submit()"
                [disabled]="buscarVecinoFormSimple.invalid"
              >
                Buscar <mat-icon>search</mat-icon>
              </button>
              <span (click)="cambiarBusqueda()" class="text-center"
                >Aplicar filtros de búsqueda avanzada</span
              >
            </div>
          </div>
        </form>
        <div class="text-container">
          <p>
            Importante: los datos señalados con * son
            <strong>obligatorios</strong>
          </p>
          <p class="grey">
            Importante: completar el número de documento sin espacios, puntos ni
            guiones. Respetar el siguiente formato: 11222333
          </p>
        </div>
      </div>
    </mat-card-content>
  </div>
</mat-card>
