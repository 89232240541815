import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit {
  showToolbarUserSettings: boolean = false;
  userName: string = '';
  userRole: string = '';
  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this._authService.userLogged$.subscribe((e) => {
      this.showToolbarUserSettings = e;
    });
    this._authService.user$.subscribe((userInfo) => {
      this.userName = userInfo?.sAMAccountName ? userInfo?.sAMAccountName : '';
      this.userRole = userInfo?.cn ? userInfo?.cn : '';
    });
  }

  logout(): void {
    this._authService.userLogged$.next(false);
    this._router.navigate(['auth/login']);
  }
}
